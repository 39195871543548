/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import moment from "moment";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { END } from "redux-saga";
import {
	adsDetik,
} from "Helpers/utils";
import { loadSelf } from "@fdn/profile_store";
import Env from "Consts/env";
import cookie from "cookie";
import { wrapper } from "@/Store/index";
import { loadArticleDate } from "@/Store/ArticleDate/lib/actions";
import { loadArticlePopular } from "@/Store/ArticlePopular/lib/actions";

const Layout = dynamic(
	() => import("Containers/layout"),
);

const DateContainer = dynamic(
	() => import("Containers/date_container"),
);

const ArchiveYear = ({ ...props }) => {

	const prop = props;
	const router = useRouter();
	const dispatch = useDispatch();
	const storeData = useSelector((state) => state);
	const [StateRender, setStateRender] = useState(0);

	const handleReloadData = ({ page = 1, limit = 10 }) => {

		dispatch(loadArticleDate({
			page,
			limit,
			year: prop.query?.year,
		}));

	};

	const updatePagination = (params) => {

		const path = window.location.pathname;

		router.push(
			{
				pathname: "/[year]",
				query: {
					page: params,
					year: prop.query?.year,
				},
			},
			`${path}?page=${params}`,
			{ shallow: true, scroll: true },
		);

	};

	useEffect(() => {

		if (StateRender === 0) {

			setStateRender(1);

		} else {

			handleReloadData({ page: router.query?.page });

		}

	}, [router.query.page]);

	return (
		<Layout
			ismobile={prop.isMobile}
			self={prop.getStoreSelf}
			adsHead={prop.adsHead}
			adsFoot={prop.adsFoot}
			navbarMenu={storeData.storeMenuDrawer}
		>
			<DateContainer
				ArticlePopular={storeData.storeArticlePopular}
				ArticleData={storeData.storeArticleDate}
				Date={storeData.storeArticleDate?.data?.data?.length > 0 ? moment(storeData.storeArticleDate?.data?.data[0].created_at).format("YYYY") : ""}
				isMobile={prop.isMobile}
				updatePagination={updatePagination}
				query={prop.query}
			/>

		</Layout>
	);

};

export const getServerSideProps = wrapper.getServerSideProps(
	async ({ store, req, query }) => {

		let userAgent;
		let cookies;
		let authorization = null;

		if (req) {

			// if you are on the server and you get a "req" property from your context
			userAgent = req.headers["user-agent"]; // get the user-agent from the headers

		} else {

			// if you are on the client you can access the navigator from the window object
			userAgent = navigator.userAgent;

		}

		const isMobile = userAgent && Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
			),
		);

		const isServer = !!req;

		if (req.headers.cookie) {

			cookies = cookie.parse(req.headers.cookie);
			authorization = cookies.token;

		}

		if (authorization) {

			await store.dispatch(
				loadSelf({
					payload: {
						api_env: process.env.NEXT_PUBLIC_NODE_ENV,
						env: Env.HEADERS,
						Authorization: authorization,
						is_server: isServer,
						is_allo: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON",
					},
				}),
			);

		}

		await store.dispatch(
			loadArticleDate({
				year: query.year,
				month: query.month,
				day: query.day,
				page: query.page || 1,
				limit: 10,
			}),
		);

		await store.dispatch(
			loadArticlePopular({
				page: query.page || 1,
				limit: 5,
			}),
		);

		store.dispatch(END);
		await store.sagaTask.toPromise();

		const getStoreSelf =	!!store.getState().storeSelf.data === true
			? store.getState().storeSelf
			: [];

		const adsHead = await adsDetik("taghead", isMobile);
		const adsFoot = await adsDetik("tagfoot", isMobile);

		return {
			props: {
				query,
				isMobile,
				getStoreSelf,
				adsHead,
				adsFoot,
			},
		};

	},
);

export default ArchiveYear;
